.Item_item__1AVGx:hover {
    background-color: #009cde !important;
    color: #fff !important;
}
.Dropdown_dropdown__MKXie .Dropdown_button__jBHgz.Dropdown_button-tertiary__7teDG:hover, 
.Dropdown_dropdown__MKXie .Dropdown_button__jBHgz.Dropdown_button-tertiary__7teDG.Dropdown_active__6EPr9 {
    border: 1px solid #009cde !important;
    color: #009cde !important;
    font-size: 14px;
}
.Dropdown_dropdown__MKXie .Dropdown_button__jBHgz.Dropdown_button-tertiary__7teDG {
    background-color: transparent !important;
    border: 1px solid #009cde !important;
    color: #009cde !important;
    font-size: 14px !important;
}
