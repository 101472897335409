.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4em auto;
    gap: 1.5em;

    h4 {
        font-family: Prelo-Book;
        font-size: 34px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.25px;
        text-align: center;
        margin: 0;
        color: var(--cui-color-base);
    }

    h6 {
        font-family: Prelo-Book;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.15px;
        text-align: center;
        margin: 0;
        color: var(--cui-color-base);
    }

    .error-img {
        max-width: 270px;
    }

    a {
        text-transform: capitalize;
        color: $blue-100;
        border: solid 1px $blue-100;
        padding: 6px 16px;
        border-radius: 4px;
        gap: 8px;

        &.MuiButton-contained {
            color: $white;
            background-color: $blue-100;

            &:hover {
                background-color: $blue-120;
            }
        }

        &:hover {
            background-color: $blue-20;
            border-color: $blue-100;
        }
    }
}

