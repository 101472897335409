.MuiTextField-root {    
    height: 38px;

    .MuiFormLabel-root {
        &.Mui-focused {
            display: none;
        }

        top: 50%;
        left: 9px;
        transform: translateY(-50%);
    }

    .MuiInputBase-root {
        // background-color: #fff;
        margin-top: 0;
        
        input {
            padding: $layout-gap;
            height: 20px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: $mid-grey-80;
        }

        .MuiInputAdornment-root .material-icons-round {
            color: $dark-grey-120;
        }
    }    
}