@import "../../../cui/sass/base";

.counter {
  margin: 10px 0;
}

.form-label {
  margin-left: .71em;
  margin-top: -.71em;
  padding-left: .44em !important;
  padding-right: .44em !important;
  z-index: 2;
  background-color: $white;
  position: absolute !important;
  font-size: .75em !important;
}

.footer-buttons-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-button {
  margin-top: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
}

.alert-msg {
  flex-grow: 1;
  display: inline-flex;
  gap: 10px;
}

.alert-msg-modal {
  display: flex;
  font-size: 12px;
  gap: 15px;
  padding-top: 15px;
}

.alert-text {
  color: $red--400;
}

.card-container {
  margin: 0 0 20px 0;
  background: $grey--50;
  overflow-y: auto;
  border-radius: 8px;
  padding: 0 16px !important;
}

.more-info>.css-1x3877-MuiButtonBase-root-MuiButton-root {
  padding: 0;
}

.disabled-option {
  padding: 0 21px;
}

.change {
  min-height: 24px;
  height: fit-content;
}

.changeRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color .3s ease;

  &:hover {
    background-color: $grey--100;
  }
}

.grid-pagination {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;
}

#grid-review-changes>.dx-datagrid {
  border-radius: 8px;
}

.dx-datagrid-borders>.dx-datagrid-filter-panel,
.dx-datagrid-borders>.dx-datagrid-headers {
  border-radius: 8px 8px 0 0;
}

.dx-datagrid-borders .dx-datagrid-rowsview,
.dx-datagrid-headers+.dx-datagrid-rowsview,
.dx-datagrid-rowsview.dx-datagrid-after-headers {
  border-radius: 0 0 8px 8px;
}

.dx-datagrid-rowsview .dx-master-detail-row>.dx-master-detail-cell {
  padding: 5px 10px;
}

.status-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  border-bottom: 1px solid $mid-grey-40;
  padding: 8px;
}

.cell {
  flex: 1;
  padding: 8px;
}

.status {
  text-align: right;
}