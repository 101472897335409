.hq-page__grid {
  .dx-header-row {
    vertical-align: top;
    background-color: #e3e3e3;
  }

  .dx-datagrid-headers {
    font-size: 13px;
    color: #343434;
    font-weight: bold;
  }

  .dx-datagrid-table {
    border: none;
  }

  .dx-datagrid .dx-row>[role="columnheader"] {
    padding: 12px 0 12px 8px;
  }

  .dx-datagrid .dx-row>td {
    padding: 10px 8px;
    text-wrap: pretty;
  }

  .dx-datagrid-headers .dx-header-row {
    td {
      border-right: 1px solid #e3e3e3;
    }

    &:hover {
      td {
        border-right: 1px solid #ccc;
      }

      td:last-child {
        border-right: 1px solid #e3e3e3;
      }
    }
  }
}