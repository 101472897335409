.MuiDrawer-root .MuiPaper-root {
    background-color: var(--cui-color-bg-light);
    color: var(--cui-color-base);
    width: 100%;

    @include respond-to('sm') {
        width: 560px;            
    }
}

.cui-c_drawer {
    &-container {
        width: 100%;
        display:flex;
        flex-direction: column;
        height: 100%;
        overflow:hidden; 
        background-color: var(--cui-color-bg-base);       
    }
    
    &-header {
        display:flex;
        padding: 0 $layout-menu-gap * 2;
        height: $layout-dx;
        align-items: center;
        flex-shrink:0;
        border-bottom:1px solid var(--cui-color-border-base);
        button {
            min-width:auto;
            border-radius: 50%;            
        }
        h4 {
            margin:0;
            margin-right:auto;
            font-size: 22px;
            font-weight: 400;
        }
    }

    &-body {
        flex-grow:1;
        overflow:auto;
        padding: $layout-menu-gap * 2;        
    }

    &-footer {        
        background-color: var(--cui-color-bg-light);                
        padding: $layout-gap * 2;
        text-align:right;
    }    
}

.settings-drawer {                
    .customization-wrapper {
        .cui-c_drawer-header button {
            color: $black;
            border: none;
            border-radius: 50%;
        }
    }

    .MuiFormControl-root {
        width: 100%;

        .MuiInputLabel-root {
            color: var(--cui-color-base);
            z-index: 999;
        }

        .MuiInput-underline,
        .MuiOutlinedInput-input {
            color: var(--cui-color-base);
        }
    
        .MuiOutlinedInput-notchedOutline {
            border-color: rgba( var(--cui-color-base-rgb), .23);
        }
    
        .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: rgba( var(--cui-color-base-rgb), 1);
        }        
    }     
    
    // buttons 
    .MuiButtonBase-root {
        padding: $layout-gap;
        border-radius: $layout-gap * .5;
        border: solid 1px var(--cui-color-primary);   
        color: var(--cui-color-primary);
    }
}

