.hq-page {
  .hq-imanage-files {
    margin: 0;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
  }

  .hq-tree {
    width: 50%;
    margin: 0 1em;
  }

  .button-right {
    float: right;
    margin-right: 20px;
    margin-left: 0;
  }

  padding: 0;
}