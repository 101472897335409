@mixin subMenuPadding {
    padding: $layout-gap*.75 $layout-gap*2;
}

#cui-layout_nav {
    width: $layout-menu-width;
    position:fixed;
    z-index:11;
    background: var(--cui-color-bg-light);

    .nav-name {
        display:none;
    }

    .toggle-menu {

        & > .material-icons-round {
            position: relative;

            &::before {
                content: "";
                display: none;
                width: $icon-bg-circle-width;
                height: $icon-bg-circle-width;
                background-color: rgba($cui-color-bg-base, .2);
                border-radius: 50%;
                @include fix-center;
            }
        }

        &:hover > .material-icons-round::before {
            display: block;
        }
    }

    &.nav-expanded {
        width: $layout-menu-active;
        transition: width $sideNavAnimationSpeed ease;

        nav {
            overflow-x: hidden;
        }

        .nav-name {
            display:block;
        }

        .nav-list_lvl-one {
            font-family: $cui-font-brand-base;

            strong {
                text-decoration: underline;
            }

            .filtered-results {
                display: flex;
                align-items: center;
                height: $layout-dx;
                padding: 0 0 0 $layout-menu-padding;
                margin-bottom: $layout-menu-gap;
                border-bottom: solid 1px rgba(var(--cui-color-base-rgb), 0.23);

                & > span {
                    display: inline-block;
                    margin-right: $layout-gap;
                    color: rgba(var(--cui-color-base-rgb), .75);
                    text-transform: capitalize;
                }

                .results-num span {
                    color: rgba(var(--cui-color-base-rgb), .87);
                    padding: 0 $layout-gap*1.25;
                }
            }

            & > li > a {
                font-weight: 700;
            }

            .lvl3 {
                padding: 0 $layout-menu-gap;

                & > a {
                    font-weight: 500;
                    color: rgba(var(--cui-color-base-rgb), .6);
                    height: auto;
                    @include subMenuPadding;
                }
            }
        }

        .nav-list_lvl-two {
            padding: $layout-gap $layout-gap*2;
            font-family: $cui-font-brand-book;
            background-color: rgba($color: $menu-grey, $alpha: 0.04);

            li > span {
                font-size:.85rem;
                display:block;
                color: rgba($color: $black, $alpha: 0.38);
                @include subMenuPadding;
                margin-bottom: $layout-gap*.5;
                font-family: $cui-font-brand-base;
            }

            li > a {
                font-size:.85rem;
                height:auto;
                display:block;
                @include subMenuPadding;
                margin-bottom: $layout-gap*.5;

                &:hover {
                    text-decoration: none;
                }
            }

            .nav-list_lvl-three a {
                display: flex;
                font-family: $cui-font-brand-base;
                font-weight: 700;
                color: rgba(var(--cui-color-base-rgb), .6);

                span[class*="icons"] {
                    font-size: 1rem;
                }
            }
        }
    }

    &.nav-collapsed {
        transition: width $sideNavAnimationSpeed ease;

        nav {
            > ul > li{
                &:hover {
                    > a {
                        background-color:var(--cui-color-bg-base);
                        z-index: 2;
                        position: relative;
                    }
                    > ul {
                        visibility: visible;
                        box-shadow: 0 0 15px var(--cui-color-shadow-base);
                        clip-path: inset(0px -15px 0px 0px);
                        opacity: 1;
                    }
                }

                a {
                    padding-left: $layout-menu-gap*2;
                }
            }

            > ul > li > ul {
                box-sizing: border-box;
                padding:$layout-gap * 2;
                > li:first-child {
                    margin-top: $layout-dx;
                    // padding-top: $layout-gap*2;
                    // border-top:1px solid #ccc;
                }
            }

            .filtered-results {
                display: none;
            }

            .nav-list_lvl-two {
                font-family: $cui-font-brand-base;

                &::before {
                    content: attr(title);
                    color: var(--cui-color-primary);
                    width:100%;
                    border-bottom:1px solid $mid-grey-20;
                    position:absolute;
                    top:0;
                    left:$layout-gap*2;
                    width: calc(100% - #{$layout-menu-gap*4});
                    line-height: $layout-dx;
                    font-weight: 700;
                }

                &.show {
                    opacity: 1;
                    visibility: visible;
                }

                li > span:first-child {
                    padding-bottom: $layout-menu-gap;
                    display: block;
                    font-family: $cui-font-brand-book;
                    color: rgba($color: var(--cui-color-base-rgb), $alpha: 0.38);
                }
                a {
                    @include subMenuPadding;
                    height: auto;
                }
            }

            > ul > li > ul {
                opacity: 0;
                transition: opacity 200ms ease-in;
                visibility:hidden;
            }

            > ul > li > ul {
                background-color:var(--cui-color-bg-base);
                position:absolute;
                left: $layout-menu-width;
                height:100vh;
                overflow-y:auto;
                width: $layout-menu-active;
                top:0;
                z-index:1;
            }
        }
    }


    .cui-nav {
        position:relative;
        z-index:20;
        nav,
        menu,
        ul,
        li {
            padding:0;
            margin:0;
            list-style:none;
        }

        nav {
            height: calc(100% - #{$layout-dx * 2});
            overflow-y: auto;
            line-height: 24px;

            a {
                color: rgba(var(--cui-color-base-rgb), .75);
                transition: color 200ms linear;
            }
        }

        menu {
            height: $layout-dx;
            background: var(--cui-color-primary);
            display: flex;
            flex-direction: row;
            align-items: center;
            box-shadow: 0 0 15px var(--cui-color-shadow-base);
            clip-path: inset(0px 0px -15px 0px);
            padding-right: $layout-gap * 2;

            .menu-title {
                color: $white;
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                font-size: 1rem;

                button {
                    &:hover {
                        background-color: transparent;
                    }

                    span {
                        margin-right: 0;
                    }
                }
            }

            .menu-search {
                width: 100%;

                #menu-search-input {
                    padding:  $layout-gap 0;
                }

                #menu-search-input-label {
                    left: 0;
                }

                .input-wrapper {
                    &:hover::before {
                        border-width: 1px;
                    }

                    &::before {
                        border-color: $mid-grey-20;
                    }

                    &::after {
                        display: none;
                    }
                }

                label, input {
                    color: $white;
                }

                .input-icon {
                    opacity: .56;

                    span {
                        color: $white;
                        font-size: $layout-menu-icon-width;
                    }
                }

                .clost-btn {
                    cursor: pointer;
                    margin-right: 0;
                }
            }
        }

        a, button {
            cursor:pointer;
            text-decoration:none;
            color:#fff;
            height: $layout-dx;
            display:flex;
            align-items:center;
            position:relative;
            padding-left: $layout-menu-gap*2;
            padding-right: 0;
            background: transparent;
            border: navajowhite;

            &:hover {
                background-color: rgba($color: $menu-grey, $alpha: 0.08);
            }
        }

        .nav-name {
            font-size:.85rem;
        }

        span[class*="icons"] {
            min-width: $layout-menu-icon-width;
            width: auto;
            font-size: $layout-menu-icon-width;
            text-align:center;
            margin-right: $layout-menu-icon-width;
        }

        .nav-list_lvl-one {
            .active {
                color: var(--cui-color-primary);
            }

            .nav-accordion {
                background-color: transparent;
                box-shadow: none;

                .nav-accordion-header {
                    min-height: auto;
                    width: calc( $layout-menu-active - $layout-menu-gap*2 );
                    height: $layout-menu-row-height;
                    padding: 0 $layout-menu-gap*1.5 0 $layout-menu-gap*2;
                    font-weight: 700;
                    color: rgba(var(--cui-color-base-rgb), 0.75);

                    &.MuiAccordionSummary-root.Mui-disabled {
                        opacity: 1;
                    }

                    & > div {
                        margin: auto;
                    }
                }

                .nav-accordion-details {
                    padding: 0;

                    .nav-list_lvl-two {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }

            & > li > a {
                height: $layout-menu-row-height;

                > span[class^="material-icons-"]:last-child {
                    margin-left: auto;
                    margin-right: $layout-gap * 2;
                }
            }
        }

        .nav-list_lvl-two {

            > li:not(:first-child) {
                margin-top: calc( #{$layout-menu-gap} * .5 );
            }
        }
    }
}