.cui-c_masthead {    
    background-color: rgba(var(--cui-color-primary-rgb), .1);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 2.7rem), 0 100%);
    padding-bottom:$layout-gap*3;
    &-wrapper {
        
        background-color: rgba(var(--cui-color-primary-rgb), .1);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1.7rem), 0 100%);
        padding-top: $layout-gap * 2;
        padding-bottom: $layout-gap * 2;
        align-items:center;
        position:relative;
    }
    &-container {
        min-height:200px;
        position:relative;
        display:flex !important;
        align-items:center;

        .MuiButtonBase-root {
            padding: $layout-gap;
            border-radius: $layout-gap * .5;
            border: solid 1px var(--cui-color-primary);
            color: var(--cui-color-primary);
        }
    }

    &-image_box {
        position:absolute;
        bottom:$layout-gap * 2 *-1;
        right:$layout-gap * 4;

        &.bottom {
            bottom: $layout-gap*4;
            right: 0;
        }
    }

    h1 {
        margin:0;
        font-size:2.125rem;
        font-family:"Prelo-Book";
        margin-bottom:$layout-gap * 2;
        color: var(--cui-color-primary);
    }
    p {
        margin:0;
        margin-bottom:$layout-gap * 2;
        color: var(--cui-color-base);
        font-family: $cui-font-brand-light;
        font-size: 1.2em;
    }

    .dx-toolkit-header {
        padding: $layout-gap*8 0 $layout-gap*5;
        position: relative;

        h1 {
            color: $black;
            font-size: 4rem;
        }
    
        p {
            width: 60%;
        }

        .MuiButtonBase-root.MuiButton-contained {
            padding: $layout-gap $layout-gap*2.5;
            margin-top: $layout-gap*5;
            color: $white;
        }
    }
}
