body,
html,
#root {
    height:100%;
    margin:0;    
}

body {
    font-family:"Prelo-Book";
    background: var(--cui-color-bg-dark) !important;
    overflow: hidden;
    overscroll-behavior: none;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

// custom font size for heading and paragraph
h1, h2, h3, h4, h5, h6 {
    font-family:"Prelo-Book";
    margin: 0;
//     font-size: 3.25rem;
//     line-height: 72px;
}

// h2 {
//     font-size: 3rem;
// }

// h3 {
//     font-size: 2rem;
// }

// h4 {
//     font-size: 1.75rem;
// }

// h5 {
//     font-size: 1.375rem;
// }

// h6 {
//     font-size: 1.125rem;
// }

// p {
//     font-size: 16px;
//     line-height: 26px;
//     margin-bottom: 18px;
// }
p {
    font-family:"Prelo-Book";
    margin-top: 0;
}

blockquote {
    font-family:"Prelo-Book";
}

strong {
    font-family:"Prelo-Medium";
}

a {
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
    }
}

#root {
    overflow: auto;
}

#cui-layout {
    height: calc( 100% - #{$layout-dx} );
    display:flex;
    // background: var(--cui-bg-light);    
    box-shadow: 0 0 35px var(--cui-color-shadow-base);
    position: relative;

    &::before {
        content: "";
        height: $layout-dx;
        width: 100%;
        background-color: var(--cui-color-primary);        
        position: absolute;
        top: 0;        
    }
    
    &_main {
        flex:1;
        display:flex;
        flex-direction: column;
        align-items: stretch;
        overflow-y: scroll;
        border-top-left-radius: $layout-gap*1.25;
        z-index: 1;
    }      
    &.layout-collapsed {
        #cui-layout_main {
            margin-left: $layout-menu-width;
            transition: margin $sideNavAnimationSpeed ease;
        }
    }
    &.layout-expanded {
        #cui-layout_main {
            margin-left:$layout-menu-active;
            transition: margin $sideNavAnimationSpeed ease;
        }
    }
    &.layout-scrolled {
        .cui-header {
            box-shadow: 0 0 15px var(--cui-color-shadow-base);
        }
    }
    &[cui-layout-dx="lg"]{
        margin: 0 auto;
        max-width:1200px;
    }
    &[cui-layout-dx="xl"]{
        margin: 0 auto;
        max-width:1536px;
    }

    &.client-layout {
        &::before {
            display: none;
        }   

        #cui-layout_main {
            border-radius: 0;
        }        
    }
}

// helers
.hidden {
    display: none;
}

.invert {
    filter: brightness(0) invert(1);
}

.font-bold {
    font-weight: 900;
}

.font-bold-semi {
    font-weight: 700;
}

.font-light {
    font-weight: 400;
}

// Page Header 
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    flex-wrap: wrap;

    .page-header_left {
        display: flex;
        align-items: center;
        gap: 8px;

        h2 {
            margin: 0;
            font-family: "Prelo-Book";
            font-size: 20px;
            font-weight: 500;
            line-height: 32px;
            color: var(--cui-color-base);
        }
    }

    .page-right {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-wrap: wrap;

        button {
            text-transform: capitalize;
            color: $blue-100;
            border: solid 1px $blue-100;
            padding: 6px 16px;
            border-radius: 4px;
            gap: 8px;
            font-size: 1em;
            
            &.filter-button {
                padding: 6px;
            }

            &.grid-icon-button {
                padding: 6px;
            }

            &.button-secondary {
                border: 1px solid #64748B4D;
                color: #0000008A;
                padding: $layout-gap $layout-gap*2;

                &:hover {
                    border: 1px solid #64748B4D;
                    background: #64748B0A;
                    color: #000000DE;
                }
            }

            &.MuiButton-contained {
                color: $white;
                background-color: $blue-100;
    
                &:hover {
                    background-color: $blue-120;
                }
            }
    
            &:hover {
                background-color: $blue-20;
                border-color: $blue-100;
            }
        }
    }
}

.MuiBreadcrumbs-ol {
    margin: 0;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;

    .MuiChip-root {
        color: rgba(0, 0, 0, 0.87);
        border: none;
        cursor: default;
        display: inline-flex;
        outline: 0;
        padding: 0;
        font-size: 0.8125rem;
        box-sizing: border-box;
        transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        align-items: center;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        white-space: nowrap;
        border-radius: 16px;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        background-color: #f5f5f5;

        &.MuiButtonBase-root {
            cursor: pointer;
        }

        .MuiChip-label {
            overflow: hidden;
            white-space: nowrap;
            padding-left: 12px;
            padding-right: 12px;
            text-overflow: ellipsis;
        }
    }
}

button {

    &.grid-icon-button {
        padding: 6px;
        transform: scale(.875);
    }

    &.button-secondary {
        border: 1px solid #64748B4D;
        color: #0000008A;

        &:hover {
            border: 1px solid #64748B4D;
            background: #64748B0A;
            color: #000000DE;
        }
    }

    &.filter-button {
        padding: $layout-gap;
        min-width: auto;
    }
}

.grid-status {
    padding: 4px 6px;
    border: 1px solid #64748B4D;
    background: #64748B0A;
    color: #000000DE;
    display: inline-block;
    border-radius: 2px;

    &.Active {
        color: var(--cui-color-primary);
        border-color: var(--cui-color-primary);
        background: #CCEBF880;
    }

    &.Error {
        color: #EF4444;
        border-color: #EF444480;
        background: #ef444420;
    }
}


.folder-format {
    display: flex;
    align-items: center;
    gap: 8px;

    a {
        text-decoration: none;
        font-weight: 500;
        color: #000;
        cursor: pointer;

        &:hover {
            color: var(--cui-color-primary);
        }
    }
}

// Modals

.MuiDialogActions-spacing {
    padding: 16px !important;
}

.MuiTypography-h6 {
    font-size: 1.25rem !important;
    font-weight: 500 !important;
    line-height: 1.6 !important;
    letter-spacing: 0.0075em !important;
    font-family: Arial,Roboto,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
}

// Tabs
.cui-tabs {
    min-height: auto !important;

    button {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        svg {
            margin-bottom: 0 !important;
            opacity: .75;
        }
    }

    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
      }

    &.divide {
        .MuiTabs-scroller {
            border-bottom: 1px solid var(--cui-color-border-base);
        }
    }

    &.rounded {
        > * {
            .MuiTabs-flexContainer { 
                button { 
                    border-radius: 32px ;
                }
            }

        }
    }

    &.shade {
        > * {
            .MuiTabs-flexContainer {
        
                button {
                    &.Mui-selected {
                        background: rgba(0, 156, 222, 0.24);
                        color: var(--cui-color-primary);
                    }
                }
                > * {
                }
            }
        }
    }

    &.bar {
        min-height: auto !important;

    > * {
    .MuiTabs-flexContainer {
        text-transform: none !important;

        button {
            padding: 16px 12px;
            margin-right: 8px;
            display: flex;
            min-width: 64px !important;
            min-height: auto;
            width: auto;
            border-radius: 0;
            background: transparent;
            
        
            &.Mui-selected {
                color: var(--cui-color-primary);
                background: transparent;
            }
        }
        > * {
            text-transform: none;
        }
    }
    .MuiTabs-indicator {
        display: inline-block;
    }
}
    }

    > * {
    .MuiTabs-flexContainer {
        text-transform: none !important;

        button {
            padding: 16px 12px;
            border-radius: 4px;
            margin-right: 8px;
            background: #f2f2f2;
            display: flex;
            min-width: 64px !important;
            height: 24px;
            min-height: auto;
            width: auto;
        
            &.Mui-selected {
                background: var(--cui-color-primary);
                color: #fff;
            }
        }
        > * {
            text-transform: none;
        }
    }
        }
        .MuiTabs-indicator {
            display: none;
        }

    
}