.cui-page {
    
    background:var(--cui-color-bg-base);
    flex-grow: 1;
    border-bottom-left-radius: $layout-gap*1.25;
    position: relative;

    &::after {
        content: "";
        background: var(--cui-color-bg-light);
        width: 100%;
        height: $layout-gap*2;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    
    &_wrapper {
        overflow:hidden;
        position:relative;
        background-color: var(--cui-color-bg-base);
    }
}
//Modifier
.layout-expanded,
.layout-collapsed {
    .cui-page {
        border-top: 10px solid var(--cui-color-primary);
    }
    .cui-page_wrapper {
        top:-10px;
        border-top-left-radius: $layout-gap*1.25;
        border-bottom-left-radius: $layout-gap*1.25;
        height: calc( 100% + #{$layout-gap*1.25});
        
        // padding-bottom: $layout-gap;
    }
}

.sort-by {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px 8px;
    gap: 8px;
}

.filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: $layout-gap*2;

    label {
        margin-bottom: $layout-gap;
    }

    .MuiFormControl-root {
        margin-bottom: $layout-gap*2;
    }
}

.cui-homepage-container {
    img.full-width {
        width: 100%;
    }

    h3 {
        border-top: solid 1px $black;
        border-bottom: solid 1px $black;
        margin: 0;
        padding: $layout-gap*2.5;
        position: relative;
    }

    blockquote {
        padding: $layout-gap*7 $layout-gap*2.5;
        border-top: solid 1px $black;
        border-bottom: solid 1px $black;
        margin-bottom: $layout-gap*9;
        text-align: center;
        font-size: 1.5rem;

        svg {
            transform: rotate(180deg);
        }

        span {
            display: block;
            font-family: "Prelo-Light";
            font-size: 1rem;
            margin-top: $layout-gap;
        }
    }

    .hero {

        h1 {
            position: relative;
            color: $black;
            font-size: 4.5rem;
            line-height: 5rem;
            margin-top: 0;
            margin-bottom: $layout-gap*3;

            &::before {
                content: "Hello";
                background-color: $d-blue--50;
                font-family:"Prelo-Light";
                color: $black;
                font-size: 1.25rem;
                line-height: 1rem;
                display: flex;
                align-items: flex-end;
                width: $layout-gap*12;
                height: 74px;
                box-sizing: border-box;
                padding-bottom: $layout-gap;
                padding-left: $layout-gap*1.5;
                position: absolute;
                bottom: 100%;
                right: 100%;
                transform: rotate(-90deg);
                transform-origin: bottom right;
            }

            span {
                padding: $layout-gap $layout-gap*2 $layout-gap $layout-gap*7;
                display: inline-block;

                &.boxBg {
                    color: $white;
                    background-color: var(--cui-color-primary);
                }
            }
        }

        p {
            margin-left: $layout-gap*6.75;
            font-size: 1.5em;
        }

        a {
            margin-left: $layout-gap*7;
        }
    }

    .what-we-do {
        background-color: $blue--grey--700;
        padding: $layout-gap*3.5 $layout-gap*8 $layout-gap*5 $layout-gap*3.5;
        color: $white;
        height: 100%;
        box-sizing: border-box;
        position: relative;

        h2 {
            margin-top: 0;
        }
    }

    .how-we-can-help-box {
        background-color: $blue--grey--100;
        padding: $layout-gap*5;
        font-size: 1.3rem;
        box-sizing: border-box;
        height: 100%;
    }

    .pillars {
        .MuiGrid-item {
            display: flex;
            flex-direction: column;

            &:not(:last-child) {
                h3:after {
                    content: "";
                    height: 90%;
                    display: block;
                    width: 1px;
                    border-right: solid 1px $black;
                    position: absolute;
                    top: 5%;
                    right: 0;
                }

                .text-box {
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }

            &:first-child .text-box  {
                margin-left: 0px;
            }

            .text-box {
                background-color: $blue--grey--100;
                padding: $layout-gap*3 $layout-gap*2 $layout-gap*5.5;
                margin-top: $layout-gap*1.5;
                margin-bottom: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                span {
                    display: inline-block;
                    background-color: $black;
                    color: $white;
                    padding: $layout-gap*.625 0;
                    margin-bottom: $layout-gap;
                    margin-left: $layout-gap*-2;
                    width: 61px;
                    text-align: center;
                }

                .image-box {
                    margin-top: auto;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    // .pillars END
    
    .accent-box {
        background-color: rgba(0, 156, 222, 0.04);
        text-align: center;

        p {
             margin-bottom: 0;
        }
    }

    .section-header {
        background-color: $blue--grey--700;
        padding: $layout-gap*8 $layout-gap*2.5;
        color: $white;
        box-sizing: border-box;
        min-height: $layout-gap*25; // 206px

        h2 {
            font-family:"Prelo-Medium";
            font-size: 2.5rem;
        }
    }

    .things-we-work-on-box {
        margin-top: $layout-gap*10;

        img {
            width: 100%;
        }

        p {
            padding: $layout-gap*2.5 20% 0 $layout-gap*2.5;
        }
    }

    .mission-box {
        h4 {
            font-size: 2.25rem;
            margin-bottom: $layout-gap*4;
        }

        p {
            padding-bottom: $layout-gap*4;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        img {
            margin: $layout-gap*15 0;
            width: 100%;
        }
    }

    .team-box {
        background-color: rgba(100, 116, 139, 0.08);
        padding: $layout-gap*4 $layout-gap*2.5;
        
        h5 {
            font-size: 1.25rem;
        }

        a {
            display: block;
            margin-top: $layout-gap*9;
            font-size: .75rem;
            color: $black;
        }
    }
}
// .cui-homepage-container END

// DX toolkit
.cui-dx-toolkit_container {
    h2 {
        color: $black;
        text-transform: uppercase;
        font-size: 2.5rem;
        line-height: 3rem;
        margin-top: $layout-gap*5;
    }

    .sub-header {
        margin: $layout-gap*5 0;
    }

    .benefits-box {
        padding: $layout-gap*4;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.12);
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.12), 0px 13px 19px 2px rgba(0, 0, 0, 0.05), 0px 5px 24px 4px rgba(0, 0, 0, 0.04);
        border-radius: 4px;

        img {
            height: 100px;
        }

        h5 {
            margin: $layout-gap*5 0 $layout-gap*1.5;
        }

        p {
            text-align: center;
        }
    }

    .template-box {
        display: block;
        color: inherit;
        background: $blue--grey--100;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding-top: $layout-gap*4;
        padding-left: $layout-gap*4;
        overflow: hidden;
        text-decoration: none;
        
        &:hover {
            @include link-box-hover;
        }

        & > img {
            display: block;
            margin-top: $layout-gap*2.5;
            width: 100%;
        }
    }

    .icon-header {
        display: grid;
        grid-template-areas: 
        "icon header"
        "icon subHeader";
        grid-template-columns: 32px 1fr;
        column-gap: $layout-gap*2;
        box-sizing: border-box;
        height: 100%;
        line-height: 1rem;
        color: inherit;
        text-decoration: none;

        & > img {
            grid-area: icon;
        }

        h5 {
            grid-area: header;
            font-size: 1rem;
            font-family:"Prelo-Medium";
        }

        span {
            grid-area: subHeader;
        }
    }

    .box-link-wrapper .icon-header {
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: $layout-gap*.5;
        justify-content: center;
        align-items: center;
        padding: $layout-gap*4;

        &:hover {
            @include link-box-hover;
        }
    }

    .used-by-container {
        background: rgba(100, 116, 139, 0.08);
        border-top: solid 1px rgba(0, 0, 0, 0.12);
        border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        padding: $layout-gap*4 $layout-gap*6;

        h2 {
            margin-top: 0;
            margin-bottom: $layout-gap*3;
        }

        .icon-box {
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            background-color: $white;
            padding: $layout-gap*2.5;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > span {
                display: block;
                font-family:"Prelo-Medium";
                margin-top: $layout-gap*3;
            }

            .cui-c_iconography {
                background-color: transparent;
                width: $layout-gap*8;
                height: $layout-gap*8;

                &-id {
                    width: $layout-gap*6.6;
                    height: $layout-gap*6.6;

                    img {
                        width: $layout-gap*3.6;
                        height: $layout-gap*3.6;
                    }
                }

                &-pie {
                    width: $layout-gap*5.75;
                    height: $layout-gap*5.75;

                    i,
                    b {
                        font-size:1rem;
                        line-height: 1.5rem;
                        right: 8px;

                        &::first-letter {
                            font-size:1.5rem;
                        }
                    }
                }
            }
        } // .icon-box 
     } //.used-by-container

     .dx-stages {
        img {
            display: block;
            width: 100%;
            margin-top: $layout-gap*4;
        }
     }
}

.homepage-jumbotron {
    h1 {
        font-family:"Prelo-Book";
        color: var(--cui-color-primary);
    }
    
    display: flex;
    background: #64748B14;
    border-radius: 10px;
    padding: 32px;
    line-height: 1.5;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    position: relative;

    .jumbotron-text {
        font-family:"Prelo-Book";
        max-width: 630px;
        font-weight: 400;
        z-index: 10;
        text-shadow: 1px 1px 1px white;

        .jumbotron-title {
            font-size: 36px;
            margin: 0;
        }
    }

    .jumbotron-spacer {
        content: '';
        width: 300px;
    }

    .jumbotron-image {
        width: 100%;
        max-width: 400px;
        position: absolute;
        right: -16px;
        bottom: -16px;
        transform: rotate3d(1, 1, 1, 5deg);
        transition: all ease-in-out 300ms;


        img {
            width: 100%;
        }
        &:hover {
            transform: rotate3d(1, 1, 1, 10deg);
            transition: all ease-in-out 300ms;
        }
    
    }
}

.homepage-apps {

    a {
        text-decoration: none;

        &:visited {
            color: inherit;
        }
    }

    .app-item {
        border: 1px solid #0000001F;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 24px;
        align-items: center;
        gap: 16px;
        transition: all ease-in-out 300ms;


        .app-img {
            width: 64px;
        }

        &:hover {
            box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.08), 0px 10px 14px 1px rgba(0, 0, 0, 0.03), 0px 4px 18px 3px rgba(0, 0, 0, 0.04);
            cursor: pointer;
            transition: all ease-in-out 300ms;

        }
    }

}

@media screen and (max-width: 960px)  
{
    .jumbotron-image {
        max-width: 200px !important;
        opacity: .75;
    }

    .app-row {
        flex-wrap: wrap;
    }
}
