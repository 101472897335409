.theme-customization {    
    display: flex;
    flex-direction: column;

    h5 {
        font-size: 22px;
        font-weight: 400;
        margin: 12px 0;
        color: $black;
    }

    .inputBox {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        margin-bottom: $layout-menu-gap * 2;                
    } // inputBox

    button {
        margin-top: $layout-gap * .5;
        text-transform: capitalize;
        color: $blue-100;
        border: solid 1px $blue-100;
        margin-left: $layout-gap * 2.5;

        &.MuiButton-contained {
            color: $white;
            background-color: $blue-100;

            &:hover {
                background-color: $blue-120;
            }
        }

        &:hover {
            background-color: $blue-20;
            border-color: $blue-100;
        }
    }

    .MuiTextField-root {
        height: auto;

        .Mui-focused {
            display: block;
        }        
    }  

    .MuiInputBase-root {
        margin-top: 0;
        margin-bottom: $layout-menu-gap * 2;
        padding: $layout-gap * .75 $layout-gap;      
        border: solid 1px $mid-grey-80;
        border-radius: $layout-gap * 0.5; 
        background-color: $white;
        color: $dark-grey-120;


        &::before,
        &::after {
            display: none;
        }

        input {
            padding: 0;
        }

        .MuiSelect-select {
            padding: 0;
            text-transform: capitalize;
            display: flex;
            align-items: center;            
            
            svg {
                margin-right: $layout-gap;
            }
        }

        .MuiSelect-icon {
            right: $layout-gap;
            color: $dark-grey-140;
        }
    }
    
    .MuiFormControl-root .MuiFormLabel-root {
        color: $dark-grey-140;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        margin: $layout-gap * .5 0;
    }      

    // dark mode switch
    .MuiSwitch-root {
        color: $mid-grey-40;

        .MuiSwitch-switchBase {
            color: $mid-grey-140;

            &.Mui-checked {
                color: $blue-100;
            }
        }
    }   
    
    .custom-theme-btn-wrap {
        margin-bottom: $layout-menu-gap * 2;

        .MuiButtonBase-root {
            text-transform: capitalize;
            color: $dark-grey-140;
            border: solid 1px $mid-grey-80;
            background-color: $white;
            width: 100%;
            justify-content: flex-start;
            margin-left: 0;

            &.Mui-disabled {
                pointer-events: unset;
                cursor: not-allowed;
            }
        }
        
        // dx colorbox
        .dx-colorbox {
            visibility: hidden;
            height: 0;
        }
    }    
}

.cui-c_drawer-footer {
    background-color: $cui-color-bg-light; 
    
    button {
        margin-left: $layout-menu-gap;        

        &.btn-white  {
            color: $white;
            background-color: var(--cui-color-primary);
        }
    }
}

// Select options
.MuiPaper-root.theme-color-options {   
    background-color: transparent; 
    box-shadow: none;
    overflow: visible;
    
    .MuiMenu-list {
        border: solid 1px $dark-grey-20;    
        width: 172px;
        border-radius: $layout-gap * .5;
        box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
        background-color: $white;

        .MuiMenuItem-root {
            text-transform: capitalize;
            color: $dark-grey-140;
            display: flex;
            justify-content: flex-start;
            padding: $layout-gap;
            border: none;
        
            svg {
                margin-right: $layout-gap;
                transform: scaleX(-1);
            }
        }
    }    
}
