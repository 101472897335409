.page-footer {
    display:flex;
    align-items: center;
    font-size:.85em;
    background: var(--cui-color-bg-light);
    height: 74px;
    &_meta {
        margin:$layout-gap*2 0;
        padding-left:$layout-gap * 2;
        flex-grow:1;
        color: var(--cui-color-muted);      

        p{
            margin:0;
            margin-bottom:$layout-gap;            
            font-size: 0.75rem;
        }

        a{
            color: var(--cui-color-primary);
            font-size: 0.75rem;
        }

    }
    &_vid {
        padding-right:$layout-gap * 2;
        .cui-c_app-logo {            
            max-height:36px;            
        }
    }
}