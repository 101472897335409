.dark-mode {
    a {
        color: $white;    
    }

    .settings-drawer {
        .customization-wrapper {
            .cui-c_drawer-header button {
                color: $white;
            }
    
            background-color: $dark-grey-120;
    
            h5 {
                color: $white;
            }
    
            .theme-customization {
                .MuiFormControl-root .MuiFormLabel-root  {
                    color: $white;
                }
    
                .MuiInputBase-input {
                    color: $dark-grey-140;
                }
    
                .MuiSwitch-root .MuiSwitch-track {
                    background-color: $white;
                    opacity: 1;
                }
    
                .MuiButton-outlined {
                    color: $white;
                    border-color: $white;
    
                    &:hover {
                        background-color: $mid-grey-140;
                    }
                }            
            }    
            
            .cui-c_drawer {
                &-header button {
                    color: $white;
                }
    
                &-footer {
                    background-color: #2d2d2d;
    
                    .btn-white {
                        background-color: $white;
                        color: #111;
                    }
                }
            }        
        }

        .MuiButtonBase-root {
            color: $white;
            border-color: $white;
        }
    }                

    // cards
    .cui-c_card {
        h3 {
            color: $white;
        }

        label {
            color: $white;
        }    
    }     

    // masthead
    .cui-c_masthead {
        h1 {
            color: $white;
        }

        .MuiButtonBase-root {
            color: $white;
            border-color: $white;
        }
    }

    // nav     
    .filtered-results {
        color: $white;
    }

    #cui-layout_nav {
        .cui-nav nav a,
        &.nav-expanded .nav-list_lvl-two li > span {
            color: $white;
        }

        .nav-accordion-header span svg {
            color: $white;
        }

        .MuiSvgIcon-root {
            color: $white;
        }
    }
    
    // tiles
    .cui-c_tile-header_title,
    .cui-c_tile-header_title a,
    .cui-c_table-heading,
    .dx-datagrid-headers,
    .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
        color: $white;
    }

    .MuiChip-root {
        // color: $white;
        &:hover {
            background-color: $dark-grey-140;
            color: $dark-grey-120;
        }
    }

    .MuiBreadcrumbs-root {
        color: $dark-grey-120;
    }

    .tile-menu {
        .MuiList-root {
            background-color: $black;
            
            .MuiListItem-button:hover {
                background-color: rgba($white, .2);
            }

            .MuiDivider-root {
                border-color: $white;
            }
        }        
    }

    .drawer-dx-grid.MuiDrawer-root .MuiPaper-root {
        background-color: $dark-grey-120 !important;

        .MuiSvgIcon-root,
        .MuiSelect-select {
            color: $white;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: $white;
        }

        .MuiButtonBase-root {
            border-color: $white;
            color: $white;
        }
    }

    // dx table header
    .dx-datagrid-header-panel button.button-secondary  {
        background-color: white;
    }

    .MuiStepLabel-label {
        color: $white;
        
        &.Mui-active {
            color: var(--cui-color-base);
        }
    }

    .simple-tabpanel {
        color: var(--cui-color-base);
    }

    .cui-tabs {
        .MuiTab-root {
            color: var(--cui-color-base);
        }

        & > * .MuiTabs-flexContainer button {
            background-color: $dark-grey-20;
            color: var(--cui-color-primary);
        }

        & > * .MuiTabs-flexContainer button.Mui-selected {
            background-color: $dark-grey-60;
            color: var(--cui-color-primary);
        }
    }

}