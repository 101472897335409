.card-details-container {
  margin: 0 0 20px 0;
  overflow-y: auto;
  max-height: 500px;
  padding: 0;
}

.card-details {
  min-width: 275px;
  border-radius: 0 !important;
}