.tree-header {
  display: table;
  width: 98%;

  .cell {
    display: table-cell;

    &__tree--title {
      width: 80%;
      padding: 0 !important;
    }

    &__counter {
      width: 20%;
      text-align: right;
      font-size: small !important;
      padding: 0 !important;
    }
    &--hide {
      display: none;
    }
  }
}
.hq-page {
  .dx-radiobutton-icon::before {
    width: 14px;
    height: 14px;
  }

  .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    margin-top: -12px;
    margin-left: 4px;
    width: 8px;
    height: 8px;
  }

  .dx-show-invalid-badge.dx-radiogroup.dx-radiogroup-vertical.dx-widget {
    float: left;
    padding-top: 6px;
    width: 24px;
  }

  .dx-item.dx-treeview-item {
    padding-left: 26px;
  }

  .dx-radio-value-container {
    padding-left: 3px;
  }

  .dx-treeview-item-with-checkbox .dx-checkbox {
    top: 9px;
  }
}
