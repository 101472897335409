* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
  }
  
 

  .button{
    /* display: flex;
    justify-content: flex-end; */
    font-size: 18px;
    margin-bottom: 20px;
 
   
  }
.button1{

  background-color:#009cde;
  color:"#fff";

}

.button1:disabled{


  background-color: white;
  color:"#fff";
}

.a{
  display: flex;
  justify-content: flex-end;
}

.select{
    width: 150px;
    margin : 20px;
}

.select1{
  font-size: 15px;
  width: auto;
  height: auto;
}

.h2{
  display: block;
  font-size: 0.8em;
  font-family:'Times New Roman', Times, serif;
  margin-top: -1.3em;
  margin-bottom: 0.67em;
  margin-left: 20px;
  margin-right: 0;
  font-weight: bold;
}

.h3{
  display: block;
  font-size: 0.8em;
  font-family:'Times New Roman', Times, serif;

  margin-bottom: 0.67em;
  margin-left: 20px;
  margin-right: 0;
  font-weight: bold;
}
.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%
}


.active {
background-color: rgb(190, 234, 243);
border-radius: 7px ;
  
}
 .nav1 {
  font-size: 21px; 
  margin-left:20px;
  margin-right: 10px;
  color:#009cde;
  padding-right:20px;
  padding-left:20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: white;
  text-decoration: none;
  font-family: Helvetica Neue,Segoe UI,helvetica,verdana,sans-serif;

 }


.nav2 {
 font-size: 21px; 
 font-family: Helvetica Neue,Segoe UI,helvetica,verdana,sans-serif;
 margin-left:10px;
 margin-right:20px;
 color:#009cde;
 padding-right:20px;
 padding-left:20px;
 padding-top: 5px;
 padding-bottom: 5px;
 border-color: white;
 text-decoration: none;
 
}

.deleteicon {
  font-family: Helvetica Neue,Segoe UI,helvetica,verdana,sans-serif;
  font-size: 15px; 
}

