.hq-modal {
  .modal_button-cancel {
    position: absolute;
    top: 22px;
    right: 8px;

    &:hover {
      background-color: transparent;
    }
  }

  .cui-c_modal-title {
    padding: 8px 15px !important;
    // border-bottom: 2px solid #4fc1ed;
    // background-color: #e5e5e5;
    font-size: bold;
  }

  .cui-c_modal-body {
    padding-bottom: 0 !important;

    &-three {
      padding-top: 0 !important;
    }
  }

  .cui-c_modal-footer {
    background-color: #e5e5e5;
    border-top: 2px solid #4fc1ed;
    padding: 8px 15px !important;

    .hq_button {
      margin: 0;
      margin-right: 11px;
      box-shadow: none;

      &:last-child {
        margin-right: 0;
      }

      &-secondary {
        box-shadow: none;
        border-style: none;

        &:hover {
          border: 2px solid #099cde;
        }

        &:active {
          border: 2px solid #099cde;
        }

        &:focus {
          border: 2px solid #099cde;
        }
      }
      &:focus {
        color: #fff;
        background-color: #d4d4d4;
        border: 2px solid #8c8c8c;
      }

      &-selected {
        text-decoration: none;
        color: #ffffff;
        background-color: #099cde;
        border-color: #099cde;
      }
    }
  }

  &__content {
    &-header-sticky {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #fff;
    }

    .MuiBox-root:nth-child(odd) {
      background-color: #f3f3f3;
    }

    &-grid-header {
      background-color: #ddd;
      border: 1px solid #d0d0d0;
      color: #343434;
      text-align: center;

      > div > p {
        font-weight: bold;
      }
    }

    .modal_grid-item {
      padding: 12px 8px;
      border: 1px solid #d0d0d0;

      &-file {
        flex-direction: column;

        &-name {
          display: flex;
          align-items: center;
        }

        &-input {
          position: relative;
          top: 4px;
          width: 350px;
          > div > input {
            position: relative;
            padding: 6px 8px;
            z-index: 2;
            border-radius: 3px;
          }
          fieldset {
            border: 1px solid #343434 !important;
          }
        }

        &-text {
          width: 427px;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          top: 2px;
        }

        &-extension {
          padding-top: 2px;
          margin-bottom: 0;
        }
      }
    }
  }

  hr {
    border: 0.5px solid #e3e3e3;
    margin-bottom: 2rem;
  }
}
