.hq-page {
  h2.hq-title {
    font-family: "Roboto", sans-serif, Arial;
    color: #343434;
    font-size: 1rem;
    font-weight: bold;
    line-height: 24px;
    padding-bottom: 1rem;
  }
}
