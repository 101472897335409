@import "../../../cui/sass/base";

.app-container {
  position: relative;

  .scroll-control {
    position: absolute;
    bottom: 0;
    transition: left 0.3s;
    cursor: pointer;
    padding: 0;
  }

  .round-button {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: $black--12p;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $black--5p;
    }

    .scroll-button {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: $white--5p;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: background-color 0.3s;

      svg {
        font-size: 20px;
        color: $black;
      }

      &.up {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.down {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      &.left {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.right {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background-color: $white;

        svg {
          color: $blue-100;
        }
      }

      $primary-color: #e0e0e0;
      $background-color: #a0a0a0;

      &:disabled {
        background-color: $primary-color;
        cursor: not-allowed;

        svg {
          color: $background-color;
        }
      }
    }
  }

  .dx-page-indexes {
    height: 34.077px;
  }

  .dx-datagrid-pager .dx-page:only-child {
    display: none;
  }
}