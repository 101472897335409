.report-style-class {
	height: 78vh;
	margin: 0% auto;
	width: 100%;
}

.report-style-class-iframe {
	height: 100vh;
	margin: 0% auto;
	width: 100%;
}

iframe {
	border: none;
}

.accessDiv {
	align-items: top !important;
    align-content: center;
    font-weight: bold;
}

.outerDiv {
	//background-color: #006699;
	color: #fff;
	//height: 400px;
	width: 100%;
	margin: 0px auto;
	padding: 5px;
}

.leftDiv {
	background-color: #efefef;
	color: #000;
	//height: 400px;
	width: 40%;
	float: left;
}

.rightDiv {
	background-color: #efefef;
	color: #000;
	//height: 400px;
	width: 60%;
	float: right;
}

.leftButton {
	position: "absolute";
	top: 5;
	left: "40%";
	cursor: "pointer";
}

#root>div {
	border: none !important;
}