.cui-c_iconography {
    width: $service-icon-size;
    height: $service-icon-size;
    background: var(--cui-color-primary);
    position:relative;
    &-id {
        display: flex;
        height: $icon-bg-circle-width;
        width: $icon-bg-circle-width;
        background: linear-gradient(25deg, #888b8d 0%, #b7b9ba 100%);
        // @include gradient-grey;
        border-radius: 8px;
        color: #E7E7E8;
        border-top-left-radius: 10%;
        border-top-right-radius: 20%;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 5%;
 
        > span {
            font-size:0.875rem;
            margin-top:.1rem;
            margin-left:.2rem;
            position: relative;
            top: 4px;
        }

        img {
            width: 20px;
            height: 20px;
            position: relative;
            top: calc( #{$layout-gap} * .5 );
            left: calc( #{$layout-gap} * .5 );
        }
    }
    &-pie {
        position:absolute;
        bottom:0;
        right:0;
        width: calc( #{$layout-menu-width} * .5 );
        height: calc( #{$layout-menu-width} * .5 );
        background:var(--cui-color-primary);   
        background: linear-gradient(315deg, rgba(var(--cui-color-ico-base),1) 2.31%, rgba(var(--cui-color-ico-light),1) 73.02%); 
        border-top-left-radius: 90%;
        border-top-right-radius: 20%;
        border-bottom-left-radius: 10%;
        border-bottom-right-radius: 10%;
        box-shadow:0 0 .5em rgba(0,0,0, .3);
        i,
        b{
            position:absolute;
            bottom:5px;
            right:5px;
            text-align:right;
            color:#fff;
            font-family: $cui-font-brand-base;
            font-size: 0.875rem;
            line-height: 0.875rem;
            font-style:italic;
            font-weight: 700;
            &::first-letter {
                font-size:1.25rem;
            }
        }
    }

    &.no-icon {
        width: $layout-gap;
    }
}