//Import CUI Base 
@import 'base';

// dx styles

//dx data grid hover 
.cui-c_table {
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space),
    .dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
        background-color: rgba($black, 0.04);    
    }

    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, 
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, 
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, 
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
        background-color: rgba(var(--cui-color-primary-rgb), 0.04);    
    }

    .dx-checkbox-indeterminate .dx-checkbox-icon,
    .dx-checkbox-checked .dx-checkbox-icon {
        background-color: var(--cui-color-primary);
    }

    a {
        color: $grey--400;
    }

    // data grid header
    .dx-datagrid-header-panel {
        padding: $layout-gap * 4 0;

        h2 {
            font-weight: normal;
            margin: 0;
        }

        .dx-texteditor.dx-editor-filled {
            background-color: transparent;
        }

        .dx-toolbar .dx-toolbar-items-container {
            height: $layout-gap*5.25;
        }

        .dx-toolbar-after {

            .dx-toolbar-item {
                padding-left: $layout-gap*2;
            }

            .dx-button {
                height: auto;
                box-shadow: none;
                background-color: var(--cui-color-primary);
                color: var(--cui-color-bg-base);
                border-radius: $layout-gap*.5;

                .dx-button-content {
                    padding: $layout-gap*.75 $layout-gap*2;
                    height: $layout-gap*5.25;
                    font-size: 1em;
                    display: flex;
                    align-items: center;

                    > * {
                        font-size: 1.2em;
                        font-weight: 400;
                    }

                    .dx-icon {
                        color: var(--cui-color-bg-base);
                        font-size: 1.8em;
                    }
    
                    .dx-button-text {
                        text-transform: capitalize;
                    }
                }
            }
        }

        // search icon 
        .dx-searchbox {
            .dx-icon-search {
                padding: 0;
                display: flex;
                align-items: center;
                left: $layout-gap*.5;

                &::before {
                    display: block;
                    font-size: 1.5em;
                    overflow: visible;
                }
            }

            .dx-texteditor-input {
                padding-top: $layout-gap*.5;
                padding-bottom: $layout-gap*.5;
                line-height: $layout-gap*3;
                font-size: $layout-gap*2;
            }

            .dx-placeholder {
                &::before {
                    font-size: $layout-gap*2;
                    line-height: $layout-gap*3;
                    padding-top: $layout-gap*.5;
                    padding-bottom: $layout-gap*.5;
                }
            }
        }
    }

    // data grid table
    .dx-datagrid-table-fixed {
        table-layout: auto !important;
    }
}


.dark-mode {
    .cui-c_table .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space),
    .dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
        background-color: rgba($white, 0.08);    
    }

    .dx-datagrid-table .dx-data-row {
        &.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
            color: $white;
        }
    
        button {
            color: $white;
        }
    }

    .cui-c_table a {
        color: $white;
    }
}
