.cui-c_tile {
    &--active {
        [class*="-body"]{
            border-color: var(--cui-color-primary);
        }
        [class*="-image"]{
            filter:none;
        }
    }
    &-header {
        color: var(--cui-color-muted);
        min-height:34px;
        display:flex;
        align-items:center;
        &_title {
            font-size:.85rem;            
            margin-right:auto;

            a{
                text-decoration:none;
                color: var(--cui-color-muted);
            }
        }
        &_actions {
            cursor: pointer;
        }
    }

    &-image {
        height:120px;
        padding: $layout-gap;
        display:flex;
        align-items:center;
        background-color:#fff;
        border-radius:3px;
        filter:grayscale(100%);
        img {
            margin: 0 auto; 
            max-height:calc(100% - #{$layout-gap*2});
            max-width:80%;
            max-height: 100%;
        }
        span {
            display:block;
            margin:0 auto;
            font-size:4rem;
            color: var(--cui-color-muted);
        }
        .image-placeholder {
          margin:0 auto;
          font-size:96px;
          color: var(--cui-color-muted);
        }

        &:not(:last-child){
            border-bottom: 1px solid var(--cui-color-border-base);
        }
    }

    &-body {
        border:1px solid transparent;
        box-shadow: 0 0 10px var(--cui-color-shadow-base);
        border-radius:3px;
        .dxm-background {
            stroke-width: 0 !important;
        }
    }
    
    .cui-c_card{
        &-actions > button,
        &-actions > a {
            padding: $layout-gap*1.5;
            border-radius: 50%;

            &:hover {
                text-decoration: none;
            }
        }

        &-button > button {
            padding: $layout-gap;
            border-radius: $layout-gap * .5;
            background-color: var(--cui-color-primary);   
            color: $white;
        }
    }
}

.tile-menu {
    ul li {
        span {
            margin-right: 20px;
        }
    }

    hr {
        margin: $layout-gap 0;
    }
}