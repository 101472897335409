.cui-c_navigator {
    box-shadow: 0 0 15px var(--cui-color-shadow-base);
    clip-path: inset(0px 0px -15px 0px);
    position: relative;
    z-index: 999;

    .cui-c_card {
        height: $layout-dx;
        padding: 0 $layout-gap;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
            padding: 0;
        }
 
        h3 {
            font-size: 1rem;
        }
    }
}

#basic-menu .MuiButtonBase-root a {
    color: $dark-grey-120;
    text-decoration: none;
}