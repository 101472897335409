.hq-page__grid {
  .link-files {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    background-clip: padding-box;
    padding: 8px 10px;
  }

  a {
    cursor: pointer;
  }

  .link-cells > div:nth-child(even) {
    background-color: #f2f2f2;
  }

  .dx-state-hover .link-cells > div:nth-child(even) {
    background-color: #fff;
  }

  .files[role="gridcell"] {
    padding: 0 !important;
    border-bottom: 1px solid #ddd;
  }
}
