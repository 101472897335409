// Common

$black: #000000;
$white: #FFFFFF;

$black--4p: rgba($black, .04);
$black--5p: rgba($black, .05);
$black--12p: rgba($black, .12);
$black--30p: rgba($black, .30);

$white--8p: rgba($white, .08);
$white--5p: rgba($white, .05);
$white--12p: rgba($white, .12);
$white--30p: rgba($white, .3);


// Primary Light | Blue
$blue--50: #CCEBF8;
$blue--100: #98D7F1;
$blue--200: #66C3EB;
$blue--300: #33AFE4;
$blue--400: #009CDE;
$blue--500: #006E9D;
$blue--600: #00567C;
$blue--700: #004563;
$blue--800: #003248;
$blue--900: #002839;
$blue--a100: #4AC5F8;
$blue--a200: #00A9F1;
$blue--a400: #0394D2;
$blue--a700: #025E85;

// Primary Dark | Blue
$d-blue--50: #E1F5FE;
$d-blue--100: #B3E5FC;
$d-blue--200: #81D4FA;
$d-blue--300: #4FC3F7;
$d-blue--400: #29B6F6;
$d-blue--500: #03A9F4;
$d-blue--600: #039BE5;
$d-blue--700: #0288D1;
$d-blue--800: #0277BD;
$d-blue--900: #01579B;
$d-blue---a100: #80D8FF;
$d-blue---a200: #40C4FF;
$d-blue---a400: #00B0FF;
$d-blue---a700: #0091EA;

// Greys
$blue--grey--50: #F8FAFC;
$blue--grey--100: #F1F5F9;
$blue--grey--200: #E2E8F0;
$blue--grey--300: #CBD5E1;
$blue--grey--400: #94A3B8;
$blue--grey--500: #64748B;
$blue--grey--600: #475569;
$blue--grey--700: #334155;
$blue--grey--800: #1E293B;
$blue--grey--900: #0F172A;

$grey--50: #E7E7E8;
$grey--100: #C0C1C3;
$grey--200: #A1A3A5;
$grey--300: #828487;
$grey--400: #63666A;
$grey--500: #484C50;
$grey--600: #353535;
$grey--700: #353535;
$grey--800: #1D1D1D;
$grey--900: #0B0B0B;
$grey---a100: #D5D5D5;
$grey---a200: #AAAAAA;
$grey---a400: #616161;
$grey---a700: #303030;

// Secondary Colors
$teal--50: #D6EDEA;
$teal--100: #ADDBD5;
$teal--200: #85CAC1;
$teal--300: #5CB8AC;
$teal--400: #34A798;
$teal--500: #1A9887;
$teal--600: #087C6C;
$teal--700: #015C50;
$teal--800: #015146;
$teal--900: #013C33;
$teal---a100: #5BDECD;
$teal---a200: #1BD1BA;
$teal---a400: #06B49D;
$teal---a700: #008A78;

$green--50: #ECEDE1;
$green--100: #D9DAC2;
$green--200: #C7C9A5;
$green--300: #B4B787;
$green--400: #A2A569;
$green--500: #7D7F48;
$green--600: #676B28;
$green--700: #5D6120;
$green--800: #424512;
$green--900: #3D400F;
$green---a100: #DCDF89;
$green---a200: #C5CD3F;
$green---a400: #A6AC3B;
$green---a700: #656B04;

$yellow--50: #FCF0D6;
$yellow--300: #F3C35C;
$yellow--400: #F1B434;
$yellow--500: #D2930F;
$yellow--600: #A57102;
$yellow--700: #805700;
$yellow--800: #745108;
$yellow--900: #7C5C16;
$yellow---a100: #F9E0AD;
$yellow---a200: #F6D285;
$yellow---a400: #F9AE10;
$yellow---a700: #FFAE00;

$orange--50: #FAE3D2;
$orange--100: #F5C8A6;
$orange--200: #F1AD7A;
$orange--300: #EC924E;
$orange--400: #E87722;
$orange--500: #BB570D;
$orange--600: #943F00;
$orange--700: #7C3500;
$orange--800: #5A2700;
$orange--900: #3F1B00;
$orange---a100: #FAAB70;
$orange---a200: #F38634;
$orange---a400: #E06203;
$orange---a700: #723000;

$red--50: #F9CCDA;
$red--100: #F498B4;
$red--200: #EE6690;
$red--300: #E9336B;
$red--400: #E40046;
$red--500: #B10036;
$red--600: #8B002A;
$red--700: #760024;
$red--800: #5A001B;
$red--900: #490016;
$red---a100: #F90750;
$red---a200: #D60747;
$red---a400: #C0063F;
$red---a700: #65001E;

$pink--50: #FCE4EC;
$pink--100: #F8BBD0;
$pink--200: #F48FB1;
$pink--300: #F06292;
$pink--400: #EC407A;
$pink--500: #E91E63;
$pink--600: #8B002A;
$pink--700: #C2185B;
$pink--800: #AD1457;
$pink--900: #8B002A;
$pink---a100: #F50057;
$pink---a200: #FF4081;
$pink---a400: #F50057;
$pink---a700: #C51162;

$purple--50: #EBDEF2;
$purple--100: #D8BDE5;
$purple--200: #C59DD9;
$purple--300: #B27CCC;
$purple--400: #9F5CC0;
$purple--500: #823CA6;
$purple--600: #6F2296;
$purple--700: #621D85;
$purple--800: #51186F;
$purple--900: #4F166C;
$purple---a100: #BC3BFB;
$purple---a200: #AC09FE;
$purple---a400: #8214B8;
$purple---a700: #49026D;


// Text Colors

$text-primary : rgba($black, .87);
$text-secondary : rgba($black, .6);
$text-disabled : rgba($black, .38);
$text-icons : #898989;


// Primary
$primary: $blue--400;
$primary-dark: $blue--600;
$primary-light: $blue--200;
$primary-contrast: $white;
$primary-shade-4p: rgba($primary, .04);
$primary-shade-8p: rgba($primary, .08);
$primary-shade-12p: rgba($primary, .12);
$primary-shade-30p: rgba($primary, .3);
$primary-shade-50p: rgba($primary, .5);


// Secondary
$secondary: $blue--grey--400;
$secondary-dark: $blue--grey--600;
$secondary-light: $blue--grey--200;
$secondary-contrast: $black;
$secondary-shade-4p: rgba($secondary, .04);
$secondary-shade-8p: rgba($secondary, .08);
$secondary-shade-12p: rgba($secondary, .12);
$secondary-shade-30p: rgba($secondary, .3);
$secondary-shade-50p: rgba($secondary, .5);

// Other
$divider: $black--12p;
$outline: $black--30p;
$backdrop: $black--30p;
$input-background: $black--4p;
$input-line: $black--30p;
$tooltip-bg: #323232;



// Primary Colors
// blue
$blue-140: #00567C;
$blue-120: #006E9D;
$blue-100: #009CDE;
$blue-80: #33AFE4;
$blue-60: #66C3EB;
$blue-40: #98D7F1;
$blue-20: #CCEBF8;

// green
$green-140: #0E5F05;
$green-120: #26821C;
$green-100: #3F9C35;
$green-80: #6CAF5D;
$green-60: #8BC385;
$green-40: #B2D7AE;
$green-20: #D8EBD6;

// mid grey
$mid-grey-140: #51595E;
$mid-grey-120: #6C7174;
$mid-grey-100: #888B8D;
$mid-grey-80: #9FA2A3;
$mid-grey-60: #B7B9BA;
$mid-grey-40: #CFD0D1;
$mid-grey-20: #E7E7E8;

// dark grey
$dark-grey-140: #2A2D32;
$dark-grey-120: #484C50;
$dark-grey-100: #63666A;
$dark-grey-80: #828487;
$dark-grey-60: #A1A3A5;
$dark-grey-40: #C0C1C3;
$dark-grey-20: #DFE0E1;


// other colors
$menu-grey: #64748b;
$menu-grey-offset: #00748b;

// Gradients
@mixin gradient-blue {
  background: linear-gradient(315deg, $blue-100 2.31%, $blue-80 73.02%);
}

@mixin gradient-green {
  background: linear-gradient(315deg, $green-100 2.31%, $green-80 73.02%);
}

@mixin gradient-grey {
  background: linear-gradient(0deg, $dark-grey-100 0%, #6C6F73 14.42%, $dark-grey-60 100%);
}

@mixin gradient-orange {
  background: linear-gradient(315deg, $orange-100 0%, $orange-80 72.5%);
}

$cui-color-blue: $primary;
$cui-color-green: #3F9C35;
$cui-color-grey: #888B8D;
$cui-color-grey-dark: #63666A;

$cui-border-base: #f1f1f1;


$cui-font-base: Arial !default;
$cui-font-brand-base: Prelo-Medium !default;
$cui-font-brand-light: Prelo-Light !default;
$cui-font-brand-bold: Prelo-Bold !default;
$cui-font-brand-book: Prelo-Book !default;

$cui-color-ico-base: $primary;
$cui-color-ico-light: #33AFE4;


$cui-color-base: #000000 !default;
$cui-color-muted: #888B8D !default;

$cui-color-primary: $primary !default;
$cui-color-secondary: $secondary !default;
$cui-color-tertiary: #63666A !default;

$cui-color-accent: #009CDE !default;

$cui-color-border-base: #F1F1F1 !default;
$cui-color-border-light: #63666A !default;
$cui-color-border-dark: #888B8D !default;

$cui-color-bg-base: #FFF !default;
$cui-color-bg-light: #F1F5F9 !default;
$cui-color-bg-dark: #b9b9b9 !default;

$cui-color-shadow-base: #aaa !default;
//$cui-color-shadow-light: #efefef !default;

:root {
  --cui-font-base: #{$cui-font-base};
  --cui-font-brand-base: #{$cui-font-brand-base};
  --cui-font-brand-light: #{$cui-font-brand-light};
  --cui-font-brand-bold: #{$cui-font-brand-bold};


  --cui-color-ico-base: #{convertHexToRgb($cui-color-ico-base)};
  --cui-color-ico-light: #{convertHexToRgb($cui-color-ico-light)};

  --cui-color-base: #{$cui-color-base};
  --cui-color-muted: #{$cui-color-muted};
  --cui-color-primary: #{$cui-color-primary};
  --cui-color-secondary: #{$cui-color-secondary};
  --cui-color-tertiary: #{$cui-color-tertiary};

  --cui-color-base-rgb: #{convertHexToRgb($cui-color-base)};
  --cui-color-muted-rgb: #{convertHexToRgb($cui-color-muted)};
  --cui-color-primary-rgb: #{convertHexToRgb($cui-color-primary)};
  --cui-color-secondary-rgb: #{convertHexToRgb($cui-color-secondary)};
  --cui-color-tertiary-rgb: #{convertHexToRgb($cui-color-tertiary)};

  --cui-color-border-base: #{$cui-color-border-base};
  --cui-color-border-light: #{$cui-color-border-light};
  --cui-color-border-dark: #{$cui-color-border-dark};

  --cui-color-bg-base: #{$cui-color-bg-base};
  --cui-color-bg-light: #{$cui-color-bg-light};
  --cui-color-bg-dark: #{$cui-color-bg-dark};

  --cui-color-shadow-base: #{$cui-color-shadow-base};
  //--cui-color-shadow-light:#{$cui-color-shadow-light};
}

//Alpha
$set-alpha-base: .8;

//Dimensions
$layout-dx: 56px;
$layout-gap: 8px;
$layout-menu-width: 64px;
$layout-menu-row-height: 50px;
$layout-menu-gap: 10px;
$layout-menu-indent-lvl3: $layout-menu-gap * 2;


$layout-menu-icon-width: 24px;
$layout-menu-padding: calc((#{$layout-menu-width} - #{$layout-menu-icon-width}) * .5);
$layout-menu-indent: calc(#{$layout-menu-padding} * 2 + #{$layout-menu-icon-width});
$icon-bg-circle-width: 38px;

$service-icon-size: 44px;
$layout-logo-gap: 12px;

$layout-menu-active: 280px;

// animation
$sideNavAnimationSpeed: 0.3s;

// mixins
@mixin fix-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin icon-hover {
  background-color: rgba($cui-color-bg-base, .2);
  text-decoration: none;
}

@mixin link-box-hover {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.08), 0px 10px 14px 1px rgba(0, 0, 0, 0.03), 0px 4px 18px 3px rgba(0, 0, 0, 0.04);
}

//Breakpoints
$cui-breakpoints: (
  'xs': 0,
  'sm': 600px,
  'md': 900px,
  'lg': 1200px,
  'xl': 1536px) !default;