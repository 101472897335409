.cui-header {
    height: $layout-dx;
    background-color: var(--cui-color-primary);
    display:flex;
    align-items: center;
    z-index:99;
    position:sticky;
    top:0;

    &.client-header {
        margin: 0 auto;
        max-width: 1536px;
        box-shadow: 0 0 35px var(--cui-color-shadow-base);
        position: relative;

        .page-header_app-name {
            padding:0 $layout-gap * 4;

            img {
                max-height: $layout-dx - $layout-gap*3;
                max-width:240px;
            }
        }
    }

    .page-header_app {
        display:flex;             
    }                
    
    .page-header_app-name {
        height: $layout-dx;
        font-family: var(--cui-font-brand-light);
        color: #fff;
        font-size:1.4rem;
        display:flex;
        align-items:center;
        padding: 0 $layout-logo-gap; //$layout-gap * 4;
        letter-spacing: .02em;
        > span:first-child {
            padding-right:$layout-gap;
        }
        & > span {
            padding-left: $layout-gap * 1.5;
            
            b {
                font-family: $cui-font-brand-bold;
            }
        }
    }

    .page-header_meta {
        margin-left:auto;
        display:flex;
        
        &-actions {
            display:flex;
            align-items: center;
            
            a {
                &:first-child {
                    margin-right: $layout-gap;
                }

                color:#fff;
                padding: $layout-gap;
                display: flex;
                text-decoration: none;
                border-radius: 50%;

                &:hover {
                    @include icon-hover;                    
                }
            }
        }
    }    
    
}