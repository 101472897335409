.site-admin-container{
    margin: 16px;
}
.btn-provide-access{
    display: flex;
    justify-content: flex-end;
    margin: 0 0 8px 0;
}
.dx-datagrid-search-panel{
margin: 0;
}

.dx-toolbar .dx-toolbar-after {
     padding-left: 0; 
}